.pages_content {
  width: 80%;
  margin: auto;
  font-family: sans-serif;
  padding-bottom: 30px;
  > .nav_controll {
    > h1 {
      font-size: 35px;
      text-align: center;
      font-family: sans-serif;
      margin-bottom: 20px;
      transform: translateY(0);
      transition: transform 0.5s;
    }
    > h1:hover {
      transform: translateY(-5px); /* slide up on hover */
    }
  }
  .pages_boxes {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 40px;
    margin-top: 40px;
    .box {
      background-color: #ffffff57;
      width: 100%;
      height: 100px;
      padding: 8px;
      text-decoration: none;
      border-radius: 6px;
      border: 1px solid #00000069;
      color: black;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      font-size: 22px;
      text-align: center;
      display: flex;
      transition: .2s linear;
      align-items: center !important;
      justify-content: center;
      &:hover {
        background-color: #00000069;
        color: white;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
      }
    }
  }
}
.loader_sections {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}