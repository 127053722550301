.navgation_controls {
  width: 100%;
  margin: auto;
  margin-top: 20px;
  display: flex; justify-content: space-between;
  align-items: center;
  font-family: sans-serif;
  border-radius: 6px;
  border: 1px solid #777;
  margin-bottom: 20px;
  padding: 8px 20px;
  z-index: 9999;

  > a {
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
    color: black;
  }

  & .links {
    display: flex;
    a {
      text-decoration: none;
      color: black;
      transition: all 0.3s ease;
      margin-inline: 10px;
      &:hover {
        color: #525252;
        transform: scale(1.5);
      }
    }
  }
}

@media(max-width:767px) {
  .navgation_controls {
    .links {
      position: absolute;
      display: flex;
      flex-direction: column !important;
      top: -300px;
      background-color:burlywood;
      border: 1px solid #777;
      width: 90%;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 10px;
      padding: 15px;
      transition: .4s;
      a {
        background-color:beige;
        cursor: pointer;
        font-size: 22px;
        border-radius: 10px;
        padding: 5px 20px;
        margin-bottom: 10px;
        &:hover {
          color: #525252;
          transform: scale(1.05);
        }
      }
    }
    .links#active {
      top: 80px;
      z-index: 999;
    }
  }
}

.bars_btn {
  cursor: pointer;
}
@media(min-width:768px) {
  .bars_btn {
    display: none;
  }
}

