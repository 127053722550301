.Review {
  width: 80% !important;
  margin: auto;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: sans-serif;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    width: 100% !important;
    position: relative;

    .quistion {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      label {
        font-size: 15px;
        font-family: sans-serif;
        margin-bottom: 4px;
      }
    }
    .userInfo {
      display: flex;
      gap: 20px;
      label {
        display: flex;
        flex-direction: column;
        .name,
        .phone {
          font-size: 15px;
          font-family: sans-serif;
          margin-bottom: 4px;
        }
        input {
          width: 300px;
          background-color: transparent;
          border: 1px solid #000000;
          border-radius: 30px;
          height: 40px;
          outline: none;
          padding: 8px 20px;
          font-size: 22px;
          transition: all 0.3s ease;
          &:focus {
            animation: focus 0.3s ease;
          }
          &::placeholder {
            color: #000000;
            opacity: 0.5;
            font-size: 18px;
          }
        }
      }
    }
    @media(max-width: 668px) {
      .userInfo {
        flex-direction: column;
        input {
          width: 100% !important;

        }
      }
    }
  }
  .sentR {
    width: 50%;
    height: 50px;
    background-color: #38434c;
    border: none;
    border-radius: 22px;
    font-size: 22px;
    font-weight: 600;
    color: #ffffff;
    cursor: pointer;
    outline: none;
    margin-top: 20px;
    transition: 0.3s ease;
  }
  .sentR:hover {
    background-color: #2c2f24;
    height: 55px;
  }
}

@media(max-width:440px) {
  .Review form .quistion input {
      width: 300px !important;
  }
}

.offer_code_popup {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 180px;
  background-color: rgba(0, 0, 0);
  color: white;
  border-radius: 10px;
  z-index: 9999;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.offer_info {
  display: flex;
  align-items: center;
  gap: 10px;
}