.footer {
  .title  {
    font-size: 30px !important;
    font-family: sans-serif;
    margin-bottom: 20px;
  }
  .icons {
    display: flex;
    gap: 50px;
    justify-content: center;
  }
}

@media(max-width: 500px) {
  .icons {
    gap: 30px !important;
  }
}
@media(max-width: 410px) {
  .icons {
    gap: 15px !important;
  }
}