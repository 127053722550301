.home_logo {
  width: 450px;
}

@media(max-width:500px) {
  .home_logo {
    width: 300px;
  }
}

.boxes {
  a {
    text-decoration: none;
    color: #000;
  }
}