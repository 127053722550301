.loading_sect {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: sans-serif;
  h1 {
    font-size: 40px;
    margin-top: 40px;
  }
}
.loader {
  width: 500px;
}

/* loader devises */
@media(max-width:530px) {
  .loader {
    width: 300px;
  } 
}