.pgae_offers {
  width: 80%;
  margin: auto;
  padding-bottom: 30px !important;

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
  }
  @media (max-width: 530px) {
    .loading > .loader_logo {
      width: 300px;
    }
  }

  .main_title {
    font-size: 35px;
    font-family: sans-serif;
    margin-bottom: 20px;
    transform: translateY(0);
    transition: transform 0.5s; /* add transition effect */
  }
  .boxes {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 30px;

    .box {
      background: rgba(0, 0, 0, 0.173);
      color: #000000;
      border-radius: 6px;
      border: 1px solid #00000069;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
      width: 100%;
      height: 320px;
      text-decoration: none;
      transition: 0.4s;
      &:hover {
        transform: scale(1.05);
      }

      .product_image {
        width: 100%;
        height: 200px;
        border-radius: 6px 6px 0 0;
      }

      .product_info {
        margin-top: 20px;
        padding: 0 10px;
        .name_percent {
          display: flex;
          align-items: center;
          justify-content: space-around;
          margin-bottom: 20px;
        }
        .take_now {
          border: 1px solid #000000;
          background-color: transparent;
          text-decoration: none;
          color: #000000;
          border-radius: 6px;
          padding: 4px 6px;
          font-size: 21px;
          font-weight: bold;
          cursor: pointer;
          border: 1px solid white;
          transition: 0.4s ease-in-out;
          background-color: rgb(92, 1, 1);
          color: white;
        }
      }
    }
  }
}

.allPartetions_contact_boxes {
  display: none !important;
}

.allPartetions_contact_boxes#active {
  background-color: rgb(0, 0, 0);
  border: 3px solid red;
  color: white;
  z-index: 999;
  position: fixed;
  width: 700px;
  height: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  gap: 30px;
  text-align: center;
  padding: 20px;

  .contact {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    a {
      text-decoration: none;
      color: white;
      font-size: 18px;
      font-weight: bold;
      transition: 0.4s ease-in-out;
      &:hover {
        color: rgb(92, 1, 1);
      }
    }
  }

  .btn_colse {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    transition: all 0.3s ease;
    &:hover {
      background-color: #333;
      color: #fff;
    }
  }

  .sections {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .allPartetions_contact_boxes {
    width: 500px !important;
  }
}
@media (max-width: 530px) {
  .allPartetions_contact_boxes {
    width: 300px !important;
    .sections {
      flex-direction: column;
      font-family: sans-serif;
      a {
        font-size: 15px;
        font-family: sans-serif;
        margin-bottom: 4px;
      }
    }
  }
}

@media (max-width: 450px) {
  .pgae_offers {
    & .boxes {
      & .box {
        height: 350px;
        .name_percent {
          flex-direction: column;
        }
      }
    }
  }
}
@media (max-width: 407px) {
  .pgae_offers {
    & .boxes {
      & .box {
        height: 380px;
      }
    }
  }
}
