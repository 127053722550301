* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --main_background: rgb(193, 165, 134);
}
body {
  overflow-x: hidden;
  text-transform: capitalize;
}
body::-webkit-scrollbar {
  width: 8px;
}
body::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 10px;
}
body::-webkit-scrollbar-track {
  background-color: #38434c;
}
body::-moz-scrollbar {
  width: 8px;
}
body::-moz-scrollbar-thumb {
  background-color: #fff;
  border-radius: 10px;
}
body::-moz-scrollbar-track {
  background-color: #38434c;
}
body::-ms-scrollbar {
  width: 8px;
}

body::-ms-scrollbar-thumb {
  background-color: #fff;
  border-radius: 10px;
}

body::-ms-scrollbar-track {
  background-color: #38434c;
}

.title {
  padding-inline: 10px;
}

p {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.App {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 30px 0;
  width: 60%;
  margin: auto;
  height: 100vh;
  .logo {
    position: relative;
    top: 0;
    animation: slideToTop 2s ease-in-out;
    transition: transform 0.3s;
  }
  .logo:hover {
    transform: scale(1.1);
  }

  .Page_Desc {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    margin-top: 10px;
    font-size: 22px;
    margin-bottom: 10px;
  }
  .boxes {
    margin: 30px 0 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: calc(100% - 20%);
    gap: 20px;
    .link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 60px;
      border: 1px solid;
      font-size: 32px;
      font-family: sans-serif;
      border-radius: 20px;
      cursor: pointer;
      transition: all 0.3s ease;
      position: relative;
      top: 0;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .contactUs {
    text-align: center;
  }
}
/* media */
@media (max-width: 450px) {
  .logo {
    width: 300px !important;
  }
  .boxes {
    width: 100% !important;
  }
}
@media (max-width: 400px) {
  .App {
    width: 85%;
    margin: auto;
    height: 100vh;
    .contactUs .info,
    .map .info {
      width: 100% !important;
    }
    .contactUs .info .title,
    .map .info .title {
      font-size: 40px;
      margin-top: 40px;
    }
    .contactUs .info .desc {
      font-size: 20px;
    }
  }
  .logo {
    width: 300px !important;
  }
  .boxes {
    width: 100% !important;
    height: 30px !important;
    font-size: 22px !important;
    margin-top: 70px !important;
  }
}
@keyframes slideToTop {
  0% {
    transform: translateY(20%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes focus {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}